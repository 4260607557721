"use client";

import React from "react";

import { useResponsive } from "@/styles";
import { useClaimId } from "@/services/ContractsService";

import { StyledButton, StyledForm, StyledTextfield } from "./ClaimId.styles";

const PREFIX = "paypipe.me/";

export type Variant = "light" | "dark";

type Styles = React.CSSProperties & {
  "--field-color"?: string;
  "--field-bg-color"?: string;
  "--button-color"?: string;
  "--button-bg-color"?: string;
};

export const CONFIG: Record<
  Variant,
  {
    buttonDesktopColor?: string;
    buttonDesktopBgColor?: string;
    fieldDesktopColor?: string;
    fieldDesktopBgColor?: string;
    buttonMobileColor?: string;
    buttonMobileBgColor?: string;
    fieldMobileColor?: string;
    fieldMobileBgColor?: string;
    styles?: Styles;
    desktopStyles?: Styles;
    mobileStyles?: Styles;
  }
> = {
  dark: {
    mobileStyles: {
      "--button-color": "#fff",
      "--button-bg-color": "var(--clr-primary-100, #5f57ff)",
    },
    desktopStyles: {
      "--button-color": "#000",
      "--button-bg-color": "#fff",
      "--field-color": "#fff",
      "--field-bg-color": "#ffffff1a",
    },
  },
  light: {
    mobileStyles: {
      "--button-color": "#fff",
      "--button-bg-color": "var(--clr-primary-100, #5f57ff)",
      "--field-color": "var(--clr-neutral-solid-500, #525866)",
      "--field-bg-color": "#fff",
    },
    desktopStyles: {
      "--button-color": "#000",
    },
  },
};

const ClaimId: React.FC<{
  variant?: Variant;
  className?: string;
  styles?: Styles;
  buttonInside?: boolean;
}> = ({
  className,
  styles = {},
  variant: variantFromProps,
  buttonInside: buttonInsideFromProps,
}) => {
  const { isScreenSmallerThanTablet } = useResponsive();
  const { id, setId, claim } = useClaimId();

  const variant =
    variantFromProps === undefined
      ? isScreenSmallerThanTablet
        ? "light"
        : "dark"
      : variantFromProps;
  const buttonInside =
    buttonInsideFromProps === undefined
      ? isScreenSmallerThanTablet
      : buttonInsideFromProps;

  const buttonJsx = (
    <StyledButton
      colorVariant="white-primary"
      type="submit"
      $themeVariant={variant}
    >
      Claim Your Paypipe ID
    </StyledButton>
  );

  return (
    <StyledForm
      $themeVariant={variant}
      onSubmit={(e) => {
        e.preventDefault();
        claim();
      }}
      className={className}
      style={{
        ...(CONFIG[variant].styles || {}),
        ...((isScreenSmallerThanTablet
          ? CONFIG[variant].mobileStyles
          : CONFIG[variant].desktopStyles) || {}),
        ...styles,
      }}
    >
      <StyledTextfield
        $themeVariant={variant}
        useFloatingLabel={false}
        variant="background"
        value={id ? `${PREFIX}${id}` : ""}
        onChange={(value) => {
          if (value.length > 1) {
            setId(value.split(PREFIX)[1]);
          } else {
            setId(value);
          }
        }}
        placeholder={`${PREFIX}yourname`}
        appendContent={buttonInside && buttonJsx}
      />
      {!buttonInside && buttonJsx}
    </StyledForm>
  );
};

export default ClaimId;
