import { Route, Routes } from "react-router-dom";

import PaypipeIdPage from "@/features/pages/user/PublicPaypipeIdPage";
import Redirect from "@/components/navigation/Redirect";
import { EXTERNAL_PATHS } from "@/config/routing";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/:id" element={<PaypipeIdPage />} />
      <Route
        path="/"
        element={
          <Redirect replace path={EXTERNAL_PATHS.PAYPIPE_ID_LANDING_PAGE} />
        }
      />
    </Routes>
  );
}

export default AppRoutes;
