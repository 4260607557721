import styled from "@emotion/styled";

import TextField from "@/components/input/TextField";
import Button from "@/components/input/Button";
import { screenLargerThan, screenSmallerThan } from "@/styles";

import { Variant } from "./ClaimId";

export const StyledForm = styled.form<{ $themeVariant: Variant }>`
  ${screenLargerThan.tablet} {
    display: flex;
    gap: 1rem;
  }
`;

export const StyledTextfield = styled(TextField)<{ $themeVariant: Variant }>`
  border-radius: 10rem;
  gap: 0;
  border: none;
  font-size: 0.925rem;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  flex: 1;
  color: var(--field-color);
  background: var(--field-bg-color);

  &::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }

  input {
    padding-inline-start: 0;
    width: calc(100% - 1px);

    &::placeholder {
      color: inherit;
    }
  }

  ${screenSmallerThan.tablet} {
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.25rem;
  }

  ${screenLargerThan.tablet} {
    min-width: 16rem;
    overflow: hidden;
    font-size: 1.25rem;

    input {
      width: max-content;
      flex-grow: 1;
      overflow: auto;
    }
  }
`;

export const StyledButton = styled(Button)<{ $themeVariant: Variant }>`
  border-radius: 10rem;
  font-size: 0.875rem;
  color: var(--button-color);
  background: var(--button-bg-color);

  ${screenSmallerThan.tablet} {
    position: sticky;
    right: 0;
    margin-inline: 0 !important;
    padding-block: 0.75rem;
  }

  ${screenLargerThan.tablet} {
    font-size: 1rem;
    padding-inline: 1.5rem;
  }
`;
