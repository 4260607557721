import lodashGet from "lodash/get";
import lodashSet from "lodash/set";

import { getClientTimezone } from "@/utils/date";
import { appFeatures } from "@/config/services";
import { generateRandomString } from "@/utils/string";
import { EXTERNAL_PATHS, SITE_PATHS } from "@/config/routing";
import { PAYPIPE_ID_SEARCH_PARAM_NAME } from "@/services/Authentication";

import {
  Package,
  SocialLink,
  Specialization,
  UserInfo,
  UserProfile,
} from "./UserService.types";
import TIMEZONE_LIST from "./temp/timezones.json";
import { SOCIAL_LINKS } from "./UserService.config";

export function getUserFullName(user: UserInfo) {
  return [user.given_name, user.family_name].filter(Boolean).join(" ");
}

export function getDefaultTimezone() {
  const clientTimezone = getClientTimezone();

  if (!clientTimezone) {
    return "";
  }

  const defaultDetails = TIMEZONE_LIST.find(({ utc }) =>
    utc.some((item) => item === clientTimezone.timeZone)
  );

  if (!defaultDetails) {
    return "";
  }

  return defaultDetails.value;
}

export function sanitizeProfileData(profileData: UserProfile) {
  profileData = JSON.parse(JSON.stringify(profileData)) as UserProfile;

  (
    ["about_me", "timezone", "location", "heading"] as (keyof UserProfile)[]
  ).forEach((fieldName) => {
    if (!profileData[fieldName]) {
      (profileData as any)[fieldName] = "";
    }
  });

  (
    [
      "profession",
      "packages",
      "portfolios",
      "links",
      "skills",
      "specializations",
      "skills_new",
    ] as (keyof UserProfile)[]
  ).forEach((fieldName) => {
    if (!profileData[fieldName]) {
      (profileData as any)[fieldName] = [];
    }
  });

  profileData.packages = profileData.packages
    .filter((item) => !!Object.keys(item).length)
    .map((item) => ({ ...item, id: item.id || generateRandomString() }));

  profileData.portfolios = profileData.portfolios
    .filter((item) => !!Object.keys(item).length)
    .map((item) => ({ ...item, id: item.id || generateRandomString() }));

  profileData.specializations = profileData.specializations.filter(
    (item) => !!Object.keys(item).length
  );

  profileData.links = profileData.links.filter(
    ({ name, url }) => !!name && !!url
  );

  [
    {
      key: "misc.canOnboard",
      defaultValue: false,
    },
    {
      key: "misc.seenNotifications",
      defaultValue: false,
    },
    {
      key: "misc.notificationCount",
      defaultValue: 0,
    },
    {
      key: "misc.featuresInterest",
      defaultValue: {},
    },
    {
      key: "misc.pushNotificationsDevices",
      defaultValue: [],
    },
    {
      key: "misc.featuresFeedback",
      defaultValue: {},
    },
  ].forEach(({ key, defaultValue }) => {
    const currentValue = lodashGet(profileData, key);
    if (currentValue === undefined) {
      lodashSet(profileData, key, defaultValue);
    }
  });

  if (appFeatures.isSupported("ACCOUNT.USE_CLIENT_TIMEZONE_AS_DEFAULT")) {
    if (!profileData.timezone) {
      profileData.timezone = getDefaultTimezone();
    }
  }

  if (appFeatures.isSupported("ACCOUNT.EDIT_LANGUAGES")) {
    if (!profileData.languages) {
      profileData.languages = [];
    }
  }

  return profileData;
}

const VALUE_REQUIRED_FIELDS = [
  "about_me",
  // "timezone"
] as (keyof UserProfile)[];
const ARRAY_REQUIRED_FIELDS = [
  // "profession",
  "portfolios",
  "links",
  // "skills",
  "specializations",
] as (keyof UserProfile)[];
export function getProfileCompletionPercentage({
  profileData,
}: {
  profileData: UserProfile;
}) {
  let fieldsWithValue = 0;

  VALUE_REQUIRED_FIELDS.forEach((fieldName) => {
    if (!!profileData[fieldName]) {
      fieldsWithValue += 1;
    }
  });

  ARRAY_REQUIRED_FIELDS.forEach((fieldName) => {
    if (!!(profileData[fieldName] as any[]).length) {
      fieldsWithValue += 1;
    }
  });

  const totalRequiredFieldsCount =
    VALUE_REQUIRED_FIELDS.length + ARRAY_REQUIRED_FIELDS.length;
  let value = (fieldsWithValue * 100) / totalRequiredFieldsCount;
  value = Math.ceil(value);
  value = Math.min(100, Math.max(0, value));

  return value;
}

export function getLinkIcon(link: SocialLink) {
  const icon =
    SOCIAL_LINKS.find(({ platformName }) => platformName === link.name)?.icon ||
    "link.svg";

  return icon;
}

function getDurationFormatted({
  duration,
  unit,
}: {
  duration: number;
  unit: string;
}) {
  const usePluralUnit = duration > 1;
  const durationUnit = unit
    ? usePluralUnit
      ? unit
      : unit.substring(0, unit.length - 1)
    : "";

  return `${duration} ${durationUnit}`;
}

export function getExperienceDurationFormatted({
  experience_duration,
  experience_duration_unit,
}: Specialization) {
  return getDurationFormatted({
    duration: experience_duration,
    unit: experience_duration_unit,
  });
}

export function getPackageDurationFormatted({
  estimated_duration,
  estimated_duration_unit,
}: Package) {
  return getDurationFormatted({
    duration: estimated_duration,
    unit: estimated_duration_unit,
  });
}

export function parseProfileData(stringifiedData: string) {
  const defaultData = sanitizeProfileData({} as UserProfile);

  if (!stringifiedData) {
    return defaultData;
  }

  try {
    let profileData = JSON.parse(stringifiedData || "{}") as UserProfile;
    profileData = sanitizeProfileData(profileData);
    return profileData;
  } catch {
    return defaultData;
  }
}

export const validatePaypipeId = (id: string) => {
  const errors: string[] = [];
  // if (id.length < 10) {
  //   errors.push("ID must be more than 10 characters");
  // }

  if (id.length > 20) {
    errors.push("ID must be less than 20 characters");
  }

  const invalidChar = id.split("").find((char) => {
    const regex = /[a-zA-Z0-9-_]/g;
    return !char.match(regex);
  });
  if (invalidChar) {
    errors.push(`Found invalid character "${invalidChar}" in ID`);
  }

  return {
    isValid: !errors.length,
    errors,
  };
};

export function getClaimIdUrl(id?: string) {
  return `${EXTERNAL_PATHS.PAYPIPE_APP}${SITE_PATHS.CLAIM_ID_PAGE}${
    id ? `?${PAYPIPE_ID_SEARCH_PARAM_NAME}=${id}` : ""
  }`;
}
