"use client";

import React from "react";
import { useParams } from "react-router-dom";

import PaypipeIdPage from "@/features/pages/user/PaypipeIdPage";
import { usePaypipeIdProfile } from "@/services/UserService";
import SplashScreen from "@/features/pages/app/SplashScreen";
import PaypipeId404Page from "@/features/pages/user/PaypipeId404Page";
import Redirect from "@/components/navigation/Redirect";
import { SITE_PATHS } from "@/config/routing";

const EditProfilePage: React.FC = () => {
  const params = useParams();
  const paypipeId = params?.id || "";
  const { accountData, profileData, isLoading } =
    usePaypipeIdProfile(paypipeId);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!accountData || !profileData || !paypipeId) {
    if (paypipeId !== "id-not-found") {
      return <Redirect path={SITE_PATHS.PAYPIPE_ID_404_PAGE} />;
    }

    return <PaypipeId404Page />;
  }

  return <PaypipeIdPage accountData={accountData} profileData={profileData} />;
};

export default EditProfilePage;
